

.itmes {
    background: #ffffff;
    border-radius: 24px;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    padding: 24px 24px 32px 24px;
    height: 239px;
    position: relative;
    box-shadow: 0px 4px 10px 0px rgba(56, 79, 111, 0.12);
}

.frame-34818 {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 43px;
    margin-top: 35vw;
 
}

.toggle {
    background: #00b177;
    border-radius: 12px;
    padding: 11px 24px 11px 24px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 43px;
    position: relative;
    width: 100%;
}

.apply {
    color: var(--neutral-white, #ffffff);
    text-align: center;
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apply-span {
    color: var(--neutral-white, #ffffff);
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.apply-span2 {
    color: var(--neutral-white, #ffffff);
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    text-transform: lowercase;
}

.frame-12870 {
  text-align: center;
  margin-top: -42vw;
}

.are-you-sure {
    color: var(--neutral-01, #333f4e);
    text-align: left;
    font-family: var(--b20-font-family, 'Roboto-Bold', sans-serif);
    font-size: var(--b20-font-size, 20px);
    font-weight: var(--b20-font-weight, 700);
   text-align: center;
}

._20-cashback-earned-enjoy-your-savings {
    text-align: center;
    font-family: var(--r14-font-family, 'Roboto-Regular', sans-serif);
    font-size: var(--r14-font-size, 14px);
    line-height: var(--r14-line-height, 150%);
    letter-spacing: var(--r14-letter-spacing, 0.2px);
    font-weight: var(--r14-font-weight, 400);
   /*  position: absolute; */
    left: 18px;
    top: 29px;
   /*  width: 244px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

._20-cashback-earned-enjoy-your-savings-span {
    color: #00b177;
    font-family: var(--r14-font-family, 'Roboto-Regular', sans-serif);
    font-size: var(--r14-font-size, 14px);
    line-height: var(--r14-line-height, 150%);
    letter-spacing: var(--r14-letter-spacing, 0.2px);
    font-weight: var(--r14-font-weight, 400);
}

._20-cashback-earned-enjoy-your-savings-span2 {
    color: var(--neutral-02, #64748b);
    font-family: var(--r14-font-family, 'Roboto-Regular', sans-serif);
    font-size: var(--r14-font-size, 14px);
    line-height: var(--r14-line-height, 150%);
    letter-spacing: var(--r14-letter-spacing, 0.2px);
    font-weight: var(--r14-font-weight, 400);
}

.coupon-1 {
    width: 97.3px;
    height: 96.1px;
    position: relative;
    /* left: 113.08px; */
    top: -177.05px;
    transform-origin: 0 0;
    transform: rotate(-2.83deg) scale(1, 1);
    /* object-fit: cover; */
    display: flex
;
    justify-content: center;
}