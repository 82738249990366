.css-1lym95h-MuiGrid-root{
    justify-content: space-between!important;
}
.css-uhb5lp{
  overflow-y: visible!important;

}

.MuiOutlinedInput-notchedOutline{

 border: none!important;
}
.css-13ao296-MuiCardContent-root:last-child {
  padding-bottom: 0px!important;
}
.css-uhb5lp{
  overflow-y: visible!important;

}

.wrapper-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 20rem);
    justify-content: center;
  }
  
  .container {
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
    background-color: white;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    margin: 0.5rem;
    border: 2px dotted yellowgreen;
  }
  
  .banner-img {
    position: absolute;
    background-image: url(https://blogs.vendify.in/wp-content/uploads/2022/06/Things-to-keep-in-mind-before-buying-a-vending-machine.png);
    height: 10rem;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .profile-img {
/*     width: 8rem;
    clip-path: circle(60px at center);
    margin-top: 4.5rem; */

    width: 7rem;
    /* clip-path: circle(60px at center); */
    margin-top: 6.5rem;
    height: 7rem;
   
    position: sticky;
    border-radius: 70px;
    background-color: blanchedalmond;
  }
  
  .name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .description {
    margin: 1rem 2rem;
    font-size: 0.9rem;
  }
  
  .btn {
    width: 100%;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(224, 28, 28);
    padding: 1rem;
    background-color: deepskyblue;
    cursor: pointer;
  }
  
  .css-1lym95h-MuiGrid-root{
    justify-content: space-between!important;
}



.wrapper-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 20rem);
    justify-content: center;
  }
  
  .container {
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px var(--clr-gray-light);
    background-color: white;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    margin: 0.5rem;
    border: 2px dotted yellowgreen;
  }
  
  .banner-img {
    position: absolute;
    background-image: url(https://blogs.vendify.in/wp-content/uploads/2022/06/Things-to-keep-in-mind-before-buying-a-vending-machine.png);
    height: 10rem;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banner-img-1 {
    position: absolute;
    background-image: url(https://vendiman.com/wp-content/uploads/2022/12/MicrosoftTeams-image-63.jpg
    );
    height: 10rem;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .profile-img {
/*     width: 8rem;
    clip-path: circle(60px at center);
    margin-top: 4.5rem; */

    width: 7rem;
    /* clip-path: circle(60px at center); */
    margin-top: 6.5rem;
    height: 7rem;
   
    position: sticky;
    border-radius: 70px;
    background-color: blanchedalmond;
  }
  
  .name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .description {
    margin: 1rem 2rem;
    font-size: 0.9rem;
  }
  
  .btn {
    width: 100%;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(224, 28, 28);
    padding: 1rem;
    background-color: deepskyblue;
    cursor: pointer;
  }
  

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    overflow-y: unset!important;
    height: 219px;
    border-radius: 10px!important;
    text-align: center;
   
    -webkit-flex-direction:none;
}

::-webkit-scrollbar {
  display: none;
}


.modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 10%;
    height: 219px;
    left: 10%;
    margin: auto;
    padding: 20px;
    position: fixed;
    top: 27%;
    width: 80%;
    z-index: 111;
}























.location-modal-img{width: 81px;
/* max-width: 400px; */
/* border-radius: 8px; */
position: absolute;
top: -63px;
/* text-align: center; */
left: 38%;
}








.location-modal-content-box{
top: 21px;

}
.share-btn{
bottom: -8px;
}


/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
    /* Your CSS styles for extra small devices */
  }
  
  /* Small devices (tablets) */
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    /* Your CSS styles for small devices */
  }
  
  /* Medium devices (laptops) */
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
 
    /* Your CSS styles for medium devices */
  }
  
  /* Large devices (desktops) */
  @media only screen and (min-width: 1025px) {
    /* Your CSS styles for large devices */
  }
  





/* Mobiles (portrait and landscape) */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    /* Your styles for mobile devices */
   
  }
  
  /* Tablets (portrait and landscape) */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Your styles for tablets */
  }
  
  /* Small desktops and laptops */
  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    /* Your styles for small desktops and laptops */
  }
  
  /* Medium desktops */
  @media only screen and (min-width: 1280px) and (max-width: 1439px) {
    /* Your styles for medium desktops */
  }
  
  /* Large desktops and high-resolution displays */
  @media only screen and (min-width: 1440px) {
    /* Your styles for large desktops and high-resolution displays */
  }
  


  /* Small height devices (e.g., mobiles) */
@media only screen and (max-height: 599px) {
    /* Your styles for small height devices */
  }
  
  /* Medium height devices (e.g., tablets) */
  @media only screen and (min-height: 600px) and (max-height: 700px) {
    /* Your styles for medium height devices */

  }
    /* Medium height devices (e.g., tablets) */
    @media only screen and (min-height: 701px) and (max-height: 899px) {
        /* Your styles for medium height devices */
        .share-btn{
            bottom: -77px;
        }
      }
      
  /* Large height devices (e.g., laptops) */
  @media only screen and (min-height: 900px) and (max-height: 1199px) {
    /* Your styles for large height devices */
  }
  
  /* Extra-large height devices (e.g., large desktops) */
  @media only screen and (min-height: 1200px) {
    /* Your styles for extra-large height devices */
  }
  
  MuiButton-root:hover {
    background-color: none!important;
  }
