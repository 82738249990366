/* Slider Container */
#slider {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  /* Slider Controls */
  .control_prev,
  .control_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    z-index: 40;
  }
  
  .control_prev {
    left: 0;
  }
  
  .control_next {
    right: 0;
  }
  
  /* Slider Images */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 50%;
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
/*   li {
    flex: 0 0 100%;
    max-width: 100%;
    display: none;
  } */
  
  li.active {
    display: block;
  }
  

  
  /* Dot Indicators */
 input[type="radio"] {
    display: none;
  } 
  
  label {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background-color: #000;
    opacity: 0.5;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  input[type="radio"]:checked + label {
    background-color: #008000; /* Change this color for active indicator */
    opacity: 1;
  }
  
  /* Responsive Styles for Large Screens */
/*   @media (min-width: 1024px) {
    .control_prev,
    .control_next {
      font-size: 48px;
    }
  
    ul {
      height: 500px;
    }
  } */
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    display: none;
}

.carousel-slide {
    position: relative;
  }
  
  .carousel-indicator {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    background-color: black; /* Change indicator color here */
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Add shadow effect */
  }



  .sliding {
    display: flex;
    justify-content: center;
    align-items: center;
   
    background-color: #f0f0f0;
  }
  
  .image-carousel {
    display: flex;
    overflow: hidden;
    max-width: 100%;
    max-height: 70vh;
  }
  
  .image-carousel  {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
  }
  
  .image-carousel img.active {
    transform: translateX(0);
  }
  
  .control-arrow{
    display: none!important;
  }