

  .proccedModal {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
   /*  top: 0; */
   background: 0 0;
    bottom:50px ;
    width: 100%; /* Full width */
    height: 10%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
   
  }
  .vl {
    border-left: 2px solid #ffff;
    height: 30px;
    margin-top: -6px;
    margin-right: 11px;

}


.label {
  border: 0px none;
  height: 18px;
  width: 9px;
}

.label .text-wrapper {
  color: #ffffff;
  font-family: "Font Awesome 6 Pro-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  text-align: center;
  top: 0;
  white-space: nowrap;
}
