.waitingLoader{
    width: 7rem;
    height: 8rem;
    margin: 0 auto;
    margin-top: 10rem;
    border-radius: 100%;
    border: 10px dotted #0000;
    border-top-color: #280503;
    animation: spinner 0.9s linear infinite;
}

@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .waitModal {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }