.linkbtn{
    height: 25px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    /* font-size: 12px; */
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2px;
    color: #00B177;
    border: 2px solid;
    padding: 10px;
    border-radius: 23px;
    /* margin-left: 7%; */
    background-color: rgb(0, 177, 119, 0.05);



    color: #00B177;
text-align: center;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 18px */
letter-spacing: 0.2px;


}