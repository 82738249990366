.qrbtn {
  display: flex;
  align-items: center;
  background-color: #00b177;

  color: white;
  padding: 5%;
  border-radius: 16px;
  width: 100%;
  justify-content: space-evenly;
  font-size: 20px;
  margin-top: 11px;
}

.modal {
  /* Hidden by default */
  position: fixed;
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: fixed;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  top: 27%;
  height: 219px;
  
  border-radius: 24px;
  z-index: 111;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.nobtn {
  display: flex;
  align-items: center;
  background-color: #00b177;

  color: white;
  padding: 5%;
  border-radius: 16px;
  width: 45%;
  justify-content: space-evenly;
  font-size: 23px;
  margin-top: 11px;
}

.yesbtn {
  display: flex;
  align-items: center;
  color: rgb(0, 177, 119);
  border: 2px solid;

  padding: 5%;
  border-radius: 16px;
  width: 45%;
  justify-content: space-evenly;
  font-size: 23px;
  margin-top: 11px;
}

#svg circle {
  /* stroke-dashoffset: 0; */
  transition: stroke-dashoffset 1s linear;
  stroke: #666;
  stroke-width: 1em;
}
#svg #bar {
  stroke: #ff9f1e;
}
#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 2em auto;
  box-shadow: 0 0 1em black;
  border-radius: 100%;
  position: relative;
}
#cont:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  box-shadow: inset 0 0 1em black;
  content: attr(data-pct) "%";
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
  text-shadow: 0 0 0.5em black;
}

/* input {
  color: #000;
} */

/* Make things perty */

/* h1,
h2 {
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 0 0.5em black;
} */
/* h2 {
  font-weight: 300;
} */
/* input {
  border: 1px solid #666;
  background: #333;
  color: #fff;
  padding: 0.5em;
  box-shadow: none;
  outline: none !important;
  margin: 1em auto;
  text-align: center;
}
 */