.modalDeactive {
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.modal-content-bpHistory {
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    top: 33%;
    height: 204px;
    left: 5%;
    border-radius: 31px;
    z-index: 111;
}

.canclebtn {
    display: flex;
    align-items: center;
    background-color: #00B177;
    color: white;
    padding: 0%;
    border-radius: 13px;
    width: 45%;
    justify-content: space-evenly;
    font-size: 20px;
    margin-top: 18px;
}    
.deactivebtn {
    display: flex;
    align-items: center;
    color: #00B177;
    border: 2px solid #00B177;
    padding: 5%;
    border-radius: 16px;
    width: 45%;
    justify-content: space-evenly;
    font-size: 18px;
    margin-top: 18px;
   background: aliceblue;
}


.modal-content-tagRecharge {
    bottom: -63px;
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    height: 279px;
    /* left: 5%; */
    border-radius: 10%;
    z-index: 111;
}


.rechargebtn {
    display: flex;
    align-items: center;
    color: #ffff;
    border: 2px solid;
    padding: 5%;
    border-radius: 16px;
    width: 90%;
    justify-content: space-evenly;
    font-size: 18px;
    margin-top: 11px;
    background: #00B177
    ;
}
.reqokbtn{

    display: flex;
    align-items: center;
    background-color: #00B177;
    color: white;
    padding: 4%;
    border-radius: 13px;
    width: 79%;
    justify-content: space-evenly;
    font-size: 20px;
    margin-top: 18px;

}
