.addToCartBtn {
  border: 2px solid rgb(0, 177, 119);
  display: flex;
  width: 100px;
  height: 32px;
  background-color: rgb(0, 177, 119);
  color: white;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
}

.Real-Rs {
  color: #718198;
  text-align: right;
  /* SM14 */
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* This will calculate to 21px if the font size is 14px */
  letter-spacing: 0.2px;
}


@media only screen and (max-width: 767px) {
    /* Styles for devices with a maximum width of 767px (typically mobile devices) */
    /* Add your styles here */

    .mydivScroll{
        height: 77vh;
    }
  }



      
      @media only screen and (max-device-height: 667px) {
        /* Styles for devices with a maximum device height of 600px (typically mobile devices) */
        /* Add your styles here */
        .mydivScroll{
            height: 67vh;
        }
      }





      @media screen and (max-width: 370px) {

        .addToCartBtn {

          width: 80px;
        }

        .Real-Rs{
          margin-left: -5px;
          font-size: 12px;
        }
        /* CSS rules for devices with a screen width of 370px or below */
        /* Example styles: */
      
        /* Add more styles as needed */
      }