.image-modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 30%);
    overflow: auto;
  }
  
  .image-modal-content {
    position: relative;
    margin: 2% auto;
    width: 99%;
    max-width: 800px;
    background-color: #fff;
    padding: 3px;
    height: 80%;
    top: 15%;
    border-radius:15px;
  }
  
.close-button-div{
    width: 37px;
    border: 3px solid white;
    position: absolute;
    height: 37px;
    right: 0px;
    border-radius: 39px;
    text-align: center;
    top: -39px;
}

  .close-button {
    position: absolute;
    top: -8px;
    right: 7px;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }
  