.RegModelImg {
    position: absolute;
    width: 8%;
    height: 40px;
    margin-left: 8%;
    margin-left: 10%;
    margin-top: 20%;
}

.EnjoyDiv{
    margin-left:9%;
  }

  .LinkExpensebtn{
    color: white;
     width: 80%;
    background-color: #15b077;
     border-width: 0px; 
     font-size: 14px;
     padding: 16px;
     /*  margin-left: 35px; */
      margin-top:30px;
  }

  .whatsappLink{
   
    font-size: 11px;
    font-weight: 900;
    ;color: rgb(0, 177, 119);
  }
  .RedImgdiv{
    display: flex;
    justify-content: center;
  }

  .verybtn {
    color: white;
    display: flex;
    width: 87%;
    background-color: #15b077;
    border-width: 0px;
    font-size: 14px;
    padding: 5%;
    /* margin-left: 24px; */
    /* margin-top: 30px; */
    justify-content: flex-end;
}