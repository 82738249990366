.price{
    color: var(--primary-5, #00B177);
text-align: right;
/* SM14 */
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.2px;
}

.viewDetails{
    color: var(--neutral-100, #718198);
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.viewDetailsIcon{
    color: var(--neutral-03, #94A3B8);
text-align: center;
font-family: Font Awesome 6 Pro;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.itemsCount{
    color: var(--neutral-02, #64748B);
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 166.667% */
}

.address{
    color: var(--neutral-03, #94A3B8);
/* R13 */
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 19.5px */
letter-spacing: 0.2px;
}

.orderIdValue{
    color: #384F6F;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.divBox{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(56, 79, 111, 0.12);
    
    
}

.countPlus{
    color: var(--neutral-100, #718198);

/* R16 */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}