@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root{
  /*Color declaration*/
  --color-primary: #FAE14B;
  --color-button-primary:#00B177;
  font-family: 'Roboto';
}

body{
background: white;
}

.gradient-color{

  background: rgb(188,220,222);
background: linear-gradient(217deg, rgba(188,220,222,0.742734593837535) 0%, rgba(230,212,234,1) 22%, rgba(225,241,237,1) 56%, rgba(255,255,255,1) 90%);
}

.daalchini-header-color{
  background: #FAE14B;
}
.daalchini-header-height{
  height: 70px;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}