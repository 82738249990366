@media only screen and (max-width: 780px){
    .logo-img.sodexo {
        top: 11px;
        right: 16px;
    }}
    .logo-img.sodexo {
        padding-left: 12%;
        width: 99px;
        height: 32px;
    }
    @media only screen and (max-width: 780px){
    .logo-img {
        position: absolute;
    }}
    
    
    
    
    
    
    
    @media only screen and (max-width: 780px){
    .logo-img {
        position: absolute;
    }}
    .logo-img.zeta {
        padding-right: 12%;
        width: 57px;
        height: 22px;
    }
    
    @media only screen and (max-width: 780px){
    .logo-img.zeta {
        top: 18px;
        left: 16px;
    }
    }
    
    
    .logo-header {
        text-align: center;
        padding: 15px;
    }
    @media only screen and (max-width: 780px){
    .logo-header {
        height: 30px;
        text-align: left;
        position: relative;
    }}
    
    
    @media only screen and (max-width: 780px){
    .logo-img.zeta {
        top: 18px;
        left: 16px;
    }
    }
    .logo-img.zeta {
        padding-right: 12%;
        width: 57px
    px
    ;
        height: 22px;
    }
    @media only screen and (max-width: 780px){
    .logo-img {
        position: absolute;
    }
    }
    @media only screen and (max-width: 780px){
    .logo-img.sodexo {
        top: 11px;
        right: 16px;
    }
    }
    
    
    .waitingLoader{
        width: 7rem;
        height: 8rem;
        margin: 0 auto;
        margin-top: 10rem;
        border-radius: 100%;
        border: 10px dotted #0000;
        border-top-color: #280503;
        animation: spinner 0.9s linear infinite;
    }
    
    @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }
    .logo-img.sodexo {
        padding-left: 12%;
        width: 99px;
        height: 32px;
    }
    .sodexowaitmodal {
        display: flex; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      }
    

    
    
 

  
    

    
  
    
    
    .modal {
        display: block; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999999; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      }
      