.makeStyles-root-1 {
    width: 100%;
    bottom: 0;
    position: fixed;
  }
  
  .MuiBox-root-3 {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 14px 0px;
  }
  
  .MuiBottomNavigation-root {
    z-index: 999;
    height: 56px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }
  
  .MuiBottomNavigationAction-root.Mui-selected {
    color: #3f51b5;
    
  }
  
  .MuiBottomNavigationAction-root {
    flex: 1;
    color: rgba(0, 0, 0, 0.54);
    padding: 6px 12px 8px;
    max-width: 168px;
    min-width: 80px;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  .MuiBottomNavigationAction-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  
  