.giftmodal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999999999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
   
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  }

  .gift-modal-content {
    background-color: #fefefe;
    /* margin: 15% auto; */
    /* padding: 20px; */
    border: 1px solid #888;
    width: 100%;
    height: 466px;
    position: fixed;
    bottom: 0;

  }
  
  .innergiftmodal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99999999999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
  
  }

  .hello input[type="text"] {
    width: 100%;
    /* box-shadow: 4px 2px 18px 0px #94A3B852; */
    margin: 8px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
    font-size: 18px;
    border-radius: 18px;
    font-size: 13px;
    background: #FFF;
    box-shadow: 4px 2px 18px 0px rgba(148, 163, 184, 0.32);
     padding-left: 20px;
    border: none;
    height: 49px;
}


.tahzeebhello input[type="text"] {
  width: 100%;
  /* box-shadow: 4px 2px 18px 0px #94A3B852; */
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  font-size: 18px;
  border-radius: 18px;
  font-size: 13px;
  background: #FFF;
  box-shadow: 4px 2px 18px 0px rgba(148, 163, 184, 0.32);
   padding-left: 20px;
  border: none;
 
}