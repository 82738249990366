.a-accordion .a-box {
  overflow: hidden;
}

.a-box-group>.a-box .a-box-tab, .a-box-group>.a-box.a-last, .a-box-group>.a-box:last-child {
  border-radius: 0 0 0.4rem 0.4rem;
}

.a-box-group>.a-box {
  margin-top: -0.1rem;
  border-radius: 0;
}

.a-box {
  display: block;
  border-radius: 0.4rem;
 /*  border: 0.1rem #ddd solid; */
  background-color: #fff;
}


.a-box-group>.a-box .a-box-tab>.a-box-inner, .a-box-group>.a-box.a-last>.a-box-inner, .a-box-group>.a-box:last-child>.a-box-inner {
  border-radius: 0 0 0.4rem 0.4rem;
}

.a-accordion .a-accordion-row-container {
  padding: 0;
}

.a-box .a-box-inner {
  border-radius: 0.4rem;
  position: relative;
  padding: 1.3rem 1.7rem 1.2rem;
}

#outer-accordion-signin-signup-page .a-accordion .a-accordion-inner {
  padding: 0 1.7rem 0;
}
.a-accordion .a-accordion-active .a-accordion-inner {
  display: block;
  background-color: #fff;
}

.a-accordion .a-accordion-inner {
  padding: 0 1.7rem 1.3rem;
  display: none;
}
form {
  margin-bottom: 1.3rem;
}
.a-spacing-medium, .a-ws .a-ws-spacing-medium {
  margin-bottom: 1.7rem!important;
}

.a-spacing-top-micro {
  margin-top: 0.4rem!important;
}
.a-input-text-group>div:first-of-type {
  margin-top: 0;
  border-top-color: #949494;
  /* box-shadow: 0 0.1rem 0 rgb(0 0 0 / 7%) inset; */
  border-radius: 0.3rem 0.3rem 0 0;
}
.a-spacing-base, .a-ws .a-ws-spacing-base {
  margin-bottom: 1.3rem!important;
}
.a-row {
  width: 100%;
}


.a-ios .a-declarative {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}


.a-section {
  margin-bottom: 1.3rem;
}
.a-button-stack .a-button {
  margin-bottom: 0.9rem;
  display: block;
}

.a-button-span12 {
  width: 100%!important;
}

.a-button-primary {
  background: #f0c14b;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
.a-button {
  background: #e7e9ec;
  border-radius: 0.3rem;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border-style: solid;
  border-width: 0.1rem;
  cursor: pointer;
  display: block;
  padding: 0;
  text-align: center;
  text-decoration: none!important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.a-button-primary .a-button-inner {
  box-shadow: 0 0.1rem 0 rgb(255 255 255 / 40%) inset;
}

.a-button-primary .a-button-inner {
  background: #f3d078;
  background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
  background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
}

.a-button .a-button-inner {
  background: #eff0f3;
  background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec);
  background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
}

.a-button-inner {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
  box-shadow: 0 0.1rem 0 rgb(255 255 255 / 60%) inset;
  border-radius: 0.2rem;
}

.a-button-input {
  position: absolute;
  background-color: transparent;
  color: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0rem;
  top: 0rem;
  opacity: .01;
  outline: 0;
  overflow: visible;
  z-index: 20;
}

#accordion-signin-signup-page .a-button-text {
  font-size: 13px;
}

.a-button-primary .a-button-text {
  color: #111;
}

.a-button-text {
  background-color: transparent;
  border: 0;
  display: block;
  font-family: Arial,sans-serif;
  font-size: 1.6rem;
  line-height: 1.35;
  margin: 0;
  outline: 0;
  padding: 1.2rem 1.6rem 1.2rem 1.7rem;
  text-align: center;
}
.moa-single-claim-input-field-container {
  display: table;
  width: 100%;
  border-radius: 8px;
background: #FFF;
box-shadow: 4px 2px 18px 0px rgba(148, 163, 184, 0.32);
}

.a-input-text-wrapper input {
  line-height: 1;
  border: 0;
  float: none;
  width: 100%;
  background-color: transparent;
  margin: 0.2rem 0 0;
  padding: 0 1.1rem;
  height: 4.3rem;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: none;
}


.continue {
  background: rgb(0, 177, 119);
   border-radius: 0.3rem;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border-style: solid;
  border-width: 0.1rem;
  cursor: pointer;
  display: block;
  padding: 0;
  text-align: center;
  text-decoration: none!important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}



.hr {
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  line-height: 0;
  padding: 0;
}

.hrspan {
  background: aliceblue;
  padding: 0 15px;
}



.inputWithIcon input[type="text"] {
  width: 100%;
/* box-shadow: 4px 2px 18px 0px #94A3B852;
 */
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  font-size: 18px;
  border-radius: 8px;
background: #FFF;
box-shadow: 4px 2px 18px 0px rgba(148, 163, 184, 0.32);
}



.inputWithIcon input[type="text"] {
  padding-left: 57px;
  border: none;
}

.inputWithIcon {
  position: relative;
 
  border-radius: 5px;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 8px;
  color: #aaa;
  transition: 0.3s;
 
}








input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}



input[type="number"] {
  color:black; /* Change the color of the input text */
  font-family: Arial, sans-serif; /* Change the font of the input text */
  margin-left: 35px; /* Set the left margin of the input */
  font-size: 20px;
 margin-top: 7px;
 width:80%
}
input[type="number"]::placeholder {
  color: gray; /* Change the color of the placeholder text */
  font-style: italic; /* Italicize the placeholder text */
}